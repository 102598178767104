
.table-responsive {
    margin-bottom: 55px;
}
.table-responsive img {
    height: 150px;
}

.table-responsive {
    width: 98%;
    margin-top: 10px;
}

ul.admimLeftPanel li a {
    cursor: pointer;
}

.quick-view.modal-view.detail-link {
    
}

.tab-menu-text li a {
    width: auto;
    padding: 0 1rem;
    margin-right: 0.4rem;
}

.main-table-row {
    margin-left: 0;
    margin-right: -18px;
}

.wishlist-table .table-header {
    font-weight: bold;
    text-transform: uppercase;
    background: whitesmoke;

}

.wishlist-table td{
    font-size: 12px !important;
}

tr.table-header , .table-header td{
    font-weight: bold;
}

.admin-layout  .tab-menu-text a, .admin-layout .htc__login__btn a {
    color: #fff !important;
}
