
/**
 header display for user profile
*/

.display-3, 
.display-3 + h3 {
    margin-bottom: 2rem;
}

.secureSection button {
    margin-top: 1.5rem;
}

.unsecureSection .actions {
    margin: 3rem auto;
   
}


.actions span {
    margin-right: 5px;
}

.love {
    font-size: 16px;
    color: red;
    font-weight: bold;
}
.unsecureSection .love::before {
    padding-left: 15px;
}

.coverlogo {
    margin: 60px auto;
}

.store__btn .right {
    margin-left: 5px; 
}

.htc__choose__wrap{
    padding-top: 58px;
    padding-bottom: 58px;
}

.htc__choose__wrap {
    height: 400px;
    padding-top: 58px !important;
    padding-bottom: 58px !important;
}
.video__wrap {
   background: rgba(0, 0, 0, 0) url(../assets/about.jpg) no-repeat scroll center center / cover !important;
   height: 400px;
   padding: 58px 0 !important;
}

