.error-list {
    list-style: none;
    margin-top: 15px;
}
.error-list li {
    text-align: center;
    color: red;
    font-size: 1.2rem;
    padding-bottom: 1rem;
}
.htc__login__btn button:active , 
.htc__login__btn button{
    border: 1px solid #d5d5d5;
    display: inline-block;
    font-size: 20px;
    height: 50px;
    line-height: 30px;
    text-align: center;
    text-transform: capitalize;
    transition: all 0.5s ease 0s;
    width: 150px;
    color: #4b4b4b;
    text-transform: uppercase;
    background-color:transparent !important;
    border-radius: 0;
    margin: 1.3rem auto;
    width: 100%;
}

.htc__login__btn button:hover {
    background-color:red !important;
    border-color:red;
    color: #fff;
}