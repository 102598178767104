.adminToolbar {
    height: 2.5rem;
    background: #333;
    color: #fff;
}

.adminToolbar a {
    color: #fff !important;
    font-weight: bold;
    padding: 0.4rem 1rem;
    float:left;
}