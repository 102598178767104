.color__list {
    margin-left: 0;
}


.color__list li.yellow a:visited,
.color__list li.yellow a:hover,
.color__list li.yellow a:active {
    opacity: 0.5;
    color: inherit;
}

.color__list li:visited,
.color__list li:hover,
.color__list li:active ,
.color__list li + li:visited,
.color__list li + li:hover,
.color__list li + li:active {
    opacity: 0.5;
    color: inherit;
}

.small {
    font-style: italic;
    margin-top: 15px;
    font-weight: bold;
}

.message {
    text-align: center;
    margin-bottom: 10px;
    text-transform: uppercase;
    font-size: 22px;
    color: green;
}

.share{
    margin-right: 10x;
}

.email_share {
    margin-left: 15px;
  
}