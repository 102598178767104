.main-image {

}

.selectedbuble {
    height: 32px !important;
    width: 32px !important;
    border: 2px solid red;
}

.overview-img {
    max-width: 600px;
}

.select__color h2 {
    margin-right: 6px;
}

.color__list li {
    text-align: center;
}

.color__list li a {
    background: lightgray;
}

.pro__dtl__btn {
    margin-top: 25px;
}

.social-sharing {
    margin-top: 15px;
}

.modal-header h3,
.messageClass {
    text-align: center;
    color: green;
    font-size: 22px;
    text-transform: uppercase;
}

.share {
    margin-right: 10px;
}