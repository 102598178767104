.paymentOptions img {
    max-height: 45px;
    margin-top: 15px;
}

#pay-button + span {
    padding-left: 15px;
    font-weight: bold;
}

.round_radio {
    background: #f10 none repeat scroll 0 0;
    border-radius: 100%;
    cursor: pointer;
    height: 25px;
    line-height: 31px;
    text-align: left;
    width: 25px;

}

.payment-menu {
    margin-top: 10px;
    margin-bottom: 10px;
}

.loader {
    border: 16px solid #f3f3f3; /* Light grey */
    border-top: 16px solid #3498db; /* Blue */
    border-radius: 50%;
    width: 120px;
    height: 120px;
    position: absolute;
    margin: 10px auto;
    z-index: 99999;
    left: 46%;
    animation: spin 2s linear infinite;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }