.main-list a.main{
    font-weight: bold !important;
    font-size: 14px !important;
    text-transform: uppercase;
}

.inner-list {
    margin-left: 35px;
    font-size: 11px;
    text-transform: uppercase;
}

.inner-list a {
    font-size: 12px !important;
    text-transform: uppercase;
}