.category-heading {
    margin: 55px 0 0px 45px;
    text-transform: uppercase;
    font-size: 20px;
}


.group-name {
    margin-right: 10px;
    font-size: 11px;
    font-style: italic;
    vertical-align: middle;
    color: red;
    font-weight: bold;
    width: auto;
    line-height: 20px;
    float:left;
}