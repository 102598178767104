
#mc_embed_signup  input {


    border: 1px solid #d9d9d9;

    color: #666666;
    height: 40px;
    padding-bottom: 0;
    padding-left: 20px;
    padding-top: 0;
    width: 100%;
}


#mc_embed_signup button {
   
    margin-top: 10px;
    color: #4b4b4b;
    font-size: 14px;
    text-transform: uppercase;
    border: 1px solid #d5d5d5;
    display: block;
    height: 44px;
    line-height: 44px;
    text-align: center;
    width: auto;
    transition: 0.3s;
    padding: 0 10px;

}

.footer-categories li a{
    text-transform: capitalize;
}

