
span.cart-quantity {
    font-size: 9px !important;
    font-weight: bold;
    color: red !important;
    line-height: 14px !important;
    padding: 2px;
    border: 1px solid;
    border-radius: 50%;
    padding-left: 4px;
    padding-right: 4px;
    background: lightyellow;
}