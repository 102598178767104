.banner-container {
    width: 100%;
    min-height: 320px;
}



/* Large screen desktop */

@media (min-width: 1366px) and (max-width: 1500px) {} @media (min-width: 1170px) and (max-width: 1500px) {

}

/* Normal desktop :992px. */

@media (min-width: 992px) and (max-width: 1169px) {
    .contact__address__inner .single__contact__address + .single__contact__address {
        margin-top: 0;
    }
    .contact__icon span {
        font-size: 22px;
        height: 40px;
        width: 40px;
        line-height: 32px;
    }
    .contact__icon {
        margin-right: 15px;
        min-width: 35px;
    }
    .contact__details p{
        font-size: 16px;
    }

}

/* Tablet desktop :768px. */

@media (min-width: 768px) and (max-width: 991px) {

    .contact__icon span {
        font-size: 22px;
        height: 40px;
        width: 40px;
        line-height: 32px;
    }
    .contact__icon {
        margin-right: 15px;
        min-width: 35px;
    }
    .contact__details p{
        font-size: 16px;
    }
    
    .contact__address__inner .single__contact__address + .single__contact__address {
        margin-top: 0;
    }
}

/* small mobile :320px. */

@media (max-width: 767px) {

    .contact__address__inner {
        display: block;
    }
    .contact__icon span {
        font-size: 19px;
        height: 30px;
        width: 30px;
        line-height: 27px;
    }
    .contact__icon {
        margin-right: 10px;
        min-width: 30px;
    }
    .contact__details p{
        font-size: 14px;
    }
}

/* Large Mobile :480px. */

@media only screen and (min-width: 480px) and (max-width: 767px) {

}