
form , optiondiv {
    width: 100%;
}
.css-2b097c-container {
    width: 500px;
}
input[type=text], input[type=email], input[type=url], input[type=number], select, option {
    width: 100%;
    padding: 4px;
}
textarea {
    background: #fff;
    border-color: #888;
}
.highlight {
    color: red;
    font-style: italic;
}
.error-list {
    list-style: none;
}
.error-list li {
    text-align: center;
    color: red;
    font-size: 1.2rem;
    padding-bottom: 1rem;
}
.htc__submit__btn button:active , 
.htc__submit__btn button{
    border: 1px solid #d5d5d5;
    display: inline-block;
    font-size: 20px;
    height: 50px;
    line-height: 30px;
    text-align: center;
    text-transform: capitalize;
    transition: all 0.5s ease 0s;
    width: 150px;
    color: #4b4b4b;
    text-transform: uppercase;
    background-color:transparent !important;
    border-radius: 0;
    margin: 1.3rem auto;
    width: 100%;
}

.htc__submit__btn button:hover {
    background-color:red !important;
    border-color:red;
    color: #fff;
}


.settings img {
    max-height: 68px;
}
.settings .ti-user::before{
    padding-right: 5px;
}

.btm__title {
    text-transform: uppercase !important;
}

.delivery__heading {
    margin: 25px auto !important;
    font-size: 22px;
}

.delivery__terms {
    text-align: left !important;
}

.offset__sosial__share {
    margin-top: 25px;
}

input[type="checkbox"] {
    cursor: pointer;
    width: 32px;
    height: 14px;
}

.editthumb {
    height: 150px;
}

.stockoptions input[type="radio"] {
    margin-right: 5px;
}

.color__list label.btn {
    font-size: 12px;
}

.select__color .color__list label a {
   
    border-radius: 100%;
    margin-right: 5px;
    display: inline-block;
    height: 14px;
    line-height: 15px;
    text-align: center;
    width: 14px;
}

.color__list label a.black {
    background: #111 none repeat scroll 0 0;
}

.color__list label a.white {
    background: #fff none repeat scroll 0 0;
    border:1px solid #f10;
}

.color__list label a.yellow {
    background: yellow none repeat scroll 0 0;
}

.color__list label a.grey {
    background: #4b4b4b none repeat scroll 0 0;
}

.color__list label a.navy {
    background: #999 none repeat scroll 0 0;
}


.select__size .color__list label a {
    background: #f1f1f1 none repeat scroll 0 0;
    color: #999;
    border-color: #f1f1f1;
    border-radius: 100%;
    margin-right: 5px;
    display: inline-block;
    height: 16px;
    line-height: 16px;
    text-align: center;
    width: 16px;
}


.product-info-delete {
    padding-left: 50px;
    width: 95%;
    text-align: center;
}

.product-info-delete h4 {
    text-align: center;
}

.product-info-delete .quick-desc {
    width: 100%;
}

.product-info-delete .htc__submit__btn {
    width: 30%;
    text-align: center;
    margin: 0 auto;
}

.main-table-row {
    margin-left: 0;
    margin-right: -18px;
}


.dropdown-item {
    width: 100%;
    float: left;
    padding: 5px 0  0 15px;
    background: lightyellow;
}

.dropdown button {
    width: 100%;
    font-weight: bold;
    font-size: 12px;
}


.btn-primary:active, .btn-primary.active, .open>.dropdown-toggle.btn-primary {
    border-color:#d5d5d5;
    
}