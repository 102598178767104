.modal-backdrop {
    opacity: 0.5;
}

.inner-body{
    width: 100%;
    height: 100%;
    min-height: 200px;
}

.subscription-form button {
    touch-action: manipulation;
    cursor: pointer;
    background-image: none;    margin-bottom: 0;
    font-weight: normal;
    text-align: center;
    vertical-align: middle;
    
    border: 1px solid #d5d5d5;
    display: inline-block;
    font-size: 14px;
    height: 45px;
    line-height: 41px;
    padding: 0 40px;
    text-transform: uppercase;
    transition: 0.4s;
    margin-top: 10px;

}

.subscription-form button:hover {
    background: red;
    color:#fff;
}